import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => {
  return (
    <div>
      <Helmet>
        <title>Yamios Pizza</title>
        <meta name="description" content="Yamios Pizza - Best Pizza In Town" />
        <meta
          name="og:title"
          property="og:title"
          content="Yamios Pizza - Best Pizza In Town"
        ></meta>
        <meta
          name="twitter:card"
          content="Yamios Pizza - Best Pizza In Town"
        ></meta>
        <link rel="canonical" href=""></link>
        <meta property="og:image" content="" />
      </Helmet>
    </div>
  )
}

export default SEO

import React from "react"
import { useRecoilState } from "recoil"
import { collapsedState } from "../../utils/recoil-atoms"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.png"
import orderOnline from "../../assets/images/IconOrderOnlineHere-wht.png"

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState)

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  // React.useEffect(() => {
  //   let elementId = document.getElementById("navbar")
  //   document.addEventListener("scroll", () => {
  //     if (window.scrollY > 170) {
  //       elementId.classList.add("is-sticky")
  //     } else {
  //       elementId.classList.remove("is-sticky")
  //     }
  //   })
  //   window.scrollTo(0, 0)
  // })

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"
  const classThree = collapsed ? "navbar-area" : "navbar-area bg-black"
  return (
    <React.Fragment>
      <div id="navbar" className={classThree}>
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <img src={logo} alt="logo" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" activeClassName="active" className="nav-link">
                      Home
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                    <Link
                      to="/pizzerias"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Pizzerias
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <Link
                      to="/menu"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Menu
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/contact"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>

                <div className="others-option d-flex align-items-center">
                  <div className="option-item">
                    <Link
                      to="/order-online"
                      activeClassName="active"
                      className="order-online-btn"
                    >
                      <img src={orderOnline} />
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar

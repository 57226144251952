import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.png"

const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="footer-content">
              <img src={logo} />
              <ul className="footer-links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                {/* <li>
                  <Link to="/pizzerias">Pizzerias</Link>
                </li> */}
                <li>
                  <Link to="/menu">Menu</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sub-footer">
          <div>© 2021 All rights reserved to YamiosPizza</div>
          <div>
            Design by:{" "}
            <a href="https://www.activedl.com" target="_blank">
              Active Digital Labs
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
